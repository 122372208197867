/* General Dashboard Styling */

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75vh;
  margin-top: 2vh;
}

/* Buttons Container */
.buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Status Message Styling */
.status-message {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 25px;
  text-align: center;
}

/* Profile Button Styling */
.profile-button {
  padding: 10px 15px; /* Adjusted for smaller buttons */
  font-size: 14px; /* Smaller font size */
  background-color: #eee;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-button.selected {
  background-color: #009BDF;
  color: white;
}

/* API Response Area */
.api-response {
  width: 100%;
}

/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  border: 3px solid black;
}

th,
td {
  padding: 8px;
  text-align: left;
  border: 1px solid #000000;
}

th {
  background-color: #f2f2f2;
  border: 3px solid black;
}

/* Paragraph Styling */
p {
  margin-bottom: 10px;
}

/* Transaction Styling */
.credit-transaction {
  color: red;
}

.debit-transaction {
  color: green;
}

/* Account Form Styling */
.account-form {
  width: 300px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
  text-align: center;
}

.modal h2 {
  margin-top: 0;
}

.modal p {
  margin-bottom: 20px;
}

.modal button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
}

.modal button:last-child {
  margin-right: 0;
}

.modal button:hover {
  background-color: #0056b3;
}

/* Button Group Container */
.button-group {
  text-align: center;
  margin-top: 20px; /* Adjust the margin as needed */
}

/* Button Group Individual Buttons */
.button-group a {
  display: inline-block;
  min-width: 150px; /* Set the minimum width to your desired size */
  min-height: 40px; /* Set the minimum height to your desired size */
  padding: 10px;
  margin: 5px;
  background-color: #007bff; /* Change the background color as desired */
  color: #fff; /* Text color */
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for long text */
}

/* Button Group Hover Effect */
.button-group a:hover {
  background-color: #0056b3; /* Change the background color on hover */
}

/* API Response Padding */
.api-response {
  padding: 0 15px;
}

/* Report Container */
.report-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px; /* Add padding to center content */
}

/* Date Report Section */
.date-report {
  margin: 20px 0; /* Space between date sections */
}

.date-title {
  font-size: 1.5em; /* Larger font for date titles */
  margin-bottom: 10px; /* Space below date title */
  border-bottom: 2px solid #ddd; /* Underline for date title */
  padding-bottom: 5px; /* Space below the line */
}

.transaction-item {
  background-color: #ffffff53;
  border: 3px solid #0e0d0d;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  max-width: 60vw;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Calibri', Arial, sans-serif; /* Calibri applied here */
  font-size: 1rem;
}

.transaction-icon img {
  width: 30px; /* Adjust icon size */
  height: 30px; /* Adjust icon size */
  margin-right: 10px; /* Leave space after the logo */
}

/* Transaction Details Layout */
.transaction-details {
  flex-grow: 1; /* Allow the details to take remaining space */
}

.transaction-description {
}

.transaction-name {
  font-weight: bold; /* Bold for transaction name */
}

.transaction-type {
  font-size: 0.9em; /* Smaller font for transaction type */
}

.transaction-amount,
.transaction-balance {
  text-align: right; /* Align amounts to the right */
  font-size: 1em; /* Regular font size for amounts */
}

/* Credit and Debit Transaction Colors */
.credit-transaction {
  color: green; /* Color for credit transactions */
}

.debit-transaction {
  color: red; /* Color for debit transactions */
}

/* Balance Colors */
.balance-green {
  color: green; /* Green color for positive balance */
}

.balance-red {
  color: red; /* Red color for negative balance */
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
  .report-container {
    padding: 0 5px;
  }

  .transaction-item {
    flex-direction: column; /* Stack items on small screens */
    align-items: flex-start; /* Align items to the left */
  }

  .transaction-details {
    width: 100%; /* Full width for details on small screens */
  }

  .transaction-amount,
  .transaction-balance,
  .transaction-fee {
    text-align: left; /* Align amounts to the left on small screens */
  }

  .transaction-fee{
    color: red;
  }
  .profile-button {
    padding: 8px 10px; /* Smaller buttons on mobile */
    font-size: 12px; /* Smaller font size */
  }

  .transaction-icon img {
    width: 40px; /* Increased icon size */
    height: 40px; /* Increased icon size */
    margin-right: 10px; /* Leave space after the logo */
  }

  /* Ensure the buttons are hidden on mobile */
  .buttons-container {
    display: none; /* Hide buttons on mobile */
  }

  .dropdown-container {
    display: inline-block; /* Show dropdown on mobile */
  }
}

/* Desktop View Styles */
@media (min-width: 769px) {
  .dropdown-container {
    display: none; /* Hide dropdown on desktop */
  }

  .buttons-container {
    display: flex; /* Show buttons on desktop */
  }
}

/* Dropdown Container */
.dropdown-container {
  position: relative;
}

/* Dropdown Toggle Button */
.dropdown-toggle {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease;
}

/* Dropdown Menu */
.dropdown-menu {
  display: block; /* Hidden by default */
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Show dropdown menu when dropdown is active */
.dropdown-container.active .dropdown-menu {
  display: block; /* Show menu when dropdown is active */
}

/* Dropdown Items */
.dropdown-item {
  background-color: white;
  color: black;
  padding: 10px 20px;
  text-align: left;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #ddd; /* Change color on hover */
}

